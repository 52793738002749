<template>
  <div class="buy-order">
      <div class="buy-order__top row">
        <div class="col-md-2">
          <p>
            Order Number:
          </p>
          <span class="buy-order__number">
            № {{item.id}}
          </span>
        </div>
        <div class="col-md-2">
          <p>
            Order Date:
          </p>
          <span>
            {{item.created_at | moment('DD.MM.YYYY HH:mm:ss') }}
          </span>
        </div>
<!--        <div class="col-md-3">-->
<!--          <p>-->
<!--            MFR:-->
<!--          </p>-->
<!--          <span>-->
<!--            {{item.mfr}}-->
<!--          </span>-->
<!--        </div>-->
        <div class="col-md-3">
          <p>
            Total Price:
          </p>
          <span class="buy-order__price">
            {{item.total_price}} $
          </span>
        </div>
        <div class="col-md-2">
          <p>
            Status:
          </p>
          <span class="buy-order__status"
                :class="item.status.status === 'new' || item.status.status === 'delivery' ? 'black' :
                item.status.status === 'paid'  || item.status.status === 'completed' ? 'green' :
                item.status.status === 'payment_in_process' || item.status.status === 'in_progress' || item.status.status === 'delivered' ? 'yellow':
                item.status.status === 'failed_payment' || item.status.status === 'canceled' ? 'red' : null"
          >
             {{ item.status.title }}
          </span>
        </div>
        <div class="arrow-open" @click="getDetailOrder(item.id)" :class="{'is-open': item.isOpen}">
          <inline-svg :src="require('@/assets/images/svg/order-arrow.svg')"></inline-svg>
        </div>
      </div>
      <div class="buy-order__bottom" :class="{'is-open': item.isOpen}">
        <div class="buy-order__content">
          <div class="buy-order__info row">
            <div class="buy-order__list col-md-4">
              <div class="buy-order__list-item">
                <p>Shipping Method</p>
                <span class="buy-order__number">{{item.shipping_method}}</span>
              </div>
              <div class="buy-order__list-item">
                <p>Supplier Total</p>
                <span class="buy-order__number">{{item.total_price}}</span>
              </div>
              <div class="buy-order__list-item">
                <p>Handling Fee</p>
                <span class="buy-order__number">{{item.handling_fee}}</span>
              </div>
              <div class="buy-order__list-item">
                <p>Commission</p>
                <span class="buy-order__number">{{item.commission}}</span>
              </div>
            </div>
            <div class="buy-order__list col-md-4">
              <div class="buy-order__list-item">
                <p>Additional Charges</p>
                <span class="buy-order__number">{{item.additional_charges}}</span>
              </div>
              <div class="buy-order__list-item">
                <p>Deposit Date</p>
                <span class="buy-order__number">{{item.deposit_date}}</span>
              </div>
            </div>
            <div class="buy-order__list col-md-4">
              <div class="buy-order__list-item">
                Tracking <a href="#"> # 270882289563</a>
              </div>
              <div class="buy-order__list-item" v-if="item.shipment && item.shipment.tracking_number">
                <a :href="'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber='+item.shipment.tracking_number+'&cntry_code=us&locale=en_US'">Purchase Order <inline-svg :src="require('@/assets/images/svg/arrow-bottom-link.svg')"/></a>
              </div>
              <div class="buy-order__list-item">
                 <p @click="generateReport"><a href="#">Shipping Document <inline-svg :src="require('@/assets/images/svg/eye-link.svg')"/></a></p>
              </div>
              <div class="buy-order__list-item">
                 <a href="#" @click.prevent="showTransaction">Transaction information <inline-svg :src="require('@/assets/images/svg/doc-ic.svg')"/></a>
              </div>
            </div>
          </div>
          <div class="buy-order__items row" v-if="sellOrder">
            <div class="item-block__list" v-if="sellOrder.products && sellOrder.products.length">
              <div class="col-md-3 col-sm-12" v-for="(item, index) in sellOrder.products" :key="index">
                <div class="item-block">
                  <div class="item-block__title mb-3">{{item.product_name}}</div>
                  <div class="item-block__info mb-5">
                    <div class="item-block__info-item">
                      <p>Pack Quantity</p>
                      <span v-if="item.pack_quantity">{{ item.pack_quantity }}</span>
                      <span v-else>--</span>
                    </div>
                    <div class="item-block__info-item">
                      <p>Pack Size</p>
                      <span>{{ item.original_pack_size }}</span>
                    </div>
                    <div class="item-block__info-item">
                      <p>NDC</p>
                      <span>{{ item.ndc }}</span>
                    </div>
                    <div class="item-block__info-item">
                      <p>Strength</p>
                      <span>{{item.strength}}</span>
                    </div>
                    <div class="item-block__info-item">
                      <p>Exp Date</p>
                      <span>{{item.expiration_date}}</span>
                    </div>
                    <div class="item-block__info-item">
                      <p>Lot#</p>
                      <span>{{ item.lot }}</span>
                    </div>
                  </div>
                  <div class="item-block__total-price">
                    <p>Total Price</p>
                    <span><span>{{item.quantity}}</span> <span>x</span> <span>${{item.price}}</span> <span>=</span> <b>${{(item.quantity * item.price).toFixed(2)}}</b></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buy-order__confirm row">
            <div class="buy-order__confirm-text col-md-8">
              You have received a new order. After confirmation, your customer will receive a notification and the status of the order will be changed. You can also cancel an order in any status.
            </div>
            <div class="buy-order__confirm-btn col-md-4">
              <button class="cancel" @click="changeToggleRejectSellOrderPopup(true)">Cancel</button>
              <button class="btn blue" @click="changeToggleConfirmSellOrderPopup(true)">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="2000"
        filename="purchase_order"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="1100px"

        ref="html2Pdf"
    >
      <section slot="pdf-content">
        <pdf/>
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import VueHtml2pdf from "vue-html2pdf";

export default {
  props:['item'],
  components:{
    VueHtml2pdf,
    pdf: () => import('../../components/orders/pdf'),
  },
  data(){
    return{
    }
  },
  computed:{
    ...mapGetters({
      sellOrder: 'orders/sellOrder',
    })
  },
  methods:{
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    showTransaction(){
      this.changeToggleTransactionPopup(true);
    },
    getDetailOrder(id){
      this.$emit('getDetailOrder', id);
      this.item.isOpen = !this.item.isOpen;
      this.$forceUpdate();
    },
    ...mapActions({
      getSoldOrderDetail: 'orders/getSoldOrderDetail'
    }),
    ...mapMutations({
      changeToggleTransactionPopup: 'general/changeToggleTransactionPopup',
      changeToggleConfirmSellOrderPopup: 'general/changeToggleConfirmSellOrderPopup',
      changeToggleRejectSellOrderPopup: 'general/changeToggleRejectSellOrderPopup',
    })
  }

}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.buy-order{
  border-radius: 27px;
  background-color: $white-grey;
  &__top{
    position: relative;
    padding:35px 50px 15px 30px;
    p{
      font-size: 14px;
      font-family: 'Inter', Arial;
      font-weight: 400;
      color: $grey;
      opacity: 0.5;
      margin-bottom: 20px;
    }
    span{
      font-size: 14px;
      font-family: 'Inter', Arial;
      font-weight: 400;
      color: $grey;
      &.buy-order__number{
        font-size: 18px;
        font-weight: 700;
        white-space: nowrap;
      }
      &.buy-order__price{
        font-size: 24px;
        font-weight: 700;
      }
      &.buy-order__status{
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        padding: 3.5px 20px 5px;
        border-radius: 19px;
        background-color: $grey;
        color: $white;
        min-width: 120px;
        text-align: center;
        &.black{
          background-color: $grey;
          position: relative;
          margin-bottom: 20px;
        }
        &.red{
          background-color: $red;
          position: relative;
          margin-bottom: 20px;
        }
        &.green{
          background-color: $green;
          position: relative;
          margin-bottom: 20px;
        }
        &.yellow{
          background-color: $yellow;
          position: relative;
          margin-bottom: 20px;
        }
      }
    }
  }
  &__bottom{
    max-height: 0;
    box-sizing: border-box;
    border-top: 1px solid transparent;
    transition: 0.3s all;
    overflow: hidden;
    &.is-open{
      max-height: 1000px;
      overflow: visible;
      border-top: 1px solid $grey02;
      transition: 0.5s all;
    }
  }
  &__content{
    border-radius: 0 0 27px 27px;
    padding: 0px 2px 2px;
  }
  &__list{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  &__list-item{
    display: flex;
    font-family: 'Inter', Arial;
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    p{
      width: 50%;
      margin-bottom: 3px;
    }
    span{
      width: 50%;
    }
    a{
      margin-bottom: 6px;
      margin-left: 3px;
      color: $blue;
      display: flex;
      align-items: center;
      svg{
        margin-left: 10px;
      }
    }
  }

  .arrow-open{
    position: absolute;
    right: 30px;
    height: 40px;
    top: calc(50% - 20px);
    cursor: pointer;
    transition: 0.5s all;
    &.is-open{
     transform: rotate(90deg);
    }
    svg{
      opacity: 0.2;
    }
  }
  &__items{
    background-color: white;
    transition: 0.3s all;
    padding: 20px 30px 40px;
    .item-block{
      margin: 0 -10px 0 -10px;
      &__title{
        font-size: 16px;
      }
      &__info{
        margin-bottom: 25px;
      }
      &__info-item{
        padding-bottom: 3px;
        p,span{
          font-size: 13px;
        }
      }
    }
  }
  &__discount{
    font-family: 'Inter', Arial;
    font-weight: 900;
    font-size: 28px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span{
      padding: 15px 30px;
      background-color: $yellow;
      color: white;
      border-radius: 24px;
    }
  }
  &__info{
    padding: 10px 15px 20px 40px;
  }
  &__confirm{
    border-radius: 0 0 27px 27px;
    padding: 20px 30px 40px;
    background-color: $white;
  }
  &__confirm-text{
    font-family: 'Inter', Arial;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: $grey;
  }
  &__confirm-btn{
    display: flex;
    justify-content: space-around;
    .cancel{
      font-family: 'Montserrat', Arial;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
